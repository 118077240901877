import React, { useRef, useEffect, useState } from 'react';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

  return (
    <div style={{
      width: '100vw',
      height: '100vh',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      rowGap: '10px',

      overflow: 'hidden',

      minWidth: 1,
      minHeight: 1,

      color: 'white',
      fontFamily: 'Gilroy',

      overflow:'hidden'
    }}>

      <div style={{ 
        // backgroundColor: 'red',
        width: '100%',
        height: '100%',

        position: 'absolute',
        top: 0,
        left: 0,

        zIndex: 1,
      }}

      >
        <img src="/background.png" style={{

          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }} />

        <div style={{
          backgroundColor: '#03110a',
          opacity: 0.8,
          width: '100%',
          height: '100%',
          position: 'absolute',

          top: 0,
          left: 0,
        }} />
      </div>

      <div style={{
        zIndex: 2,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: isMobile ? '10px' : '25px',
      }}>
        <img src="/logo_with_text.svg" style={{
          height: isMobile ? '60px' : '100px',
        }} />
      </div>

      <div style={{
        zIndex: 2,

        fontSize: isMobile ? '17px' : '25px',
        textAlign: 'center',

        width:'80%'
      }}>
        Optimize your security posture with AI-driven insights and real-time adjustments
      </div>
    </div>


  );
}

export default App;
